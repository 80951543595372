
#landing_body{
    position: relative;
    background-color: #0D0620;
    margin: 0;
    padding: 0;
    font-family: Poppins;
    font-size: 16px;
}

#banner_wrapper{
    /* margin-top: -15vh; */
    /* aspect-ratio: 16/9; */
    width: 100%;
    /* height: 95vh; */
    position: relative;
    overflow: hidden;

}

#banner_wrapper:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 400px; /* Height of the gradient */
    background: linear-gradient(to top, rgba(1, 10, 38, 1), transparent);
    pointer-events: none; /* Prevent it from interfering with interactions */
}

.react-player.shrinked{
    margin-top: -6vw;
    margin-bottom: -6vw;
}


.slide-title {
    font-size: 2rem;
    width: 50%;
}

.slider_image{
    object-fit: 'cover';
    width: 99%;
    height: 100%;
    position: relative;
    transition: transform 1s;
    border-radius: 10px;
    display: block;
    z-index: 2;
  }

  /* .slider_image:hover{
    transform: translateZ(30px);
    z-index: 1000; */
    /* position: absolute; */
  /* } */

/* .slick-list {
    margin: -50px 0px;
    padding: 50px 0px;
    position: relative;
} */

/* .slick-slide.slick-active.slick-cloned {
    position: relative;
    perspective: 100px;
}
*/


    #carouselListWrapper {
    /* margin-top: 50px; */
    position: relative;
    }



#footerSection {
    border-top: 1px solid #1C2537;
    margin-top: 2rem;
}

#cugateLogoFooter {
    max-width: 100%;
}


.genre_titles{
    font-size: 30px;
    font-weight: 500;
}

@media only screen and (max-width: 990px) {
    #banner_wrapper .swiper-button-prev,
    #banner_wrapper .swiper-button-next { 
        display: none;
    }

    .slide-title {
        font-size: 1.5rem;
        width: 70%;
    }

    .banner_slide .banner_content .slide_watchbtn {
        padding: 0.7rem 1.5rem !important;
    }

    .search_form.mobile .search-cont {
        width: auto;

    }

    #banner_wrapper{
        height: 33vh;
    
    }

    .genre_titles{
        font-size: 16px;
    }
}

@media only screen and (max-width: 600px) {

    .navbar-brand {
        margin-left: 1rem;
    }

    .navbar-brand img {
        width: 100px;
        height: auto !important;
    }

    .slide-title {
        font-size: 1rem;
        width: 100%;
    }

    .banner_slide .banner_content .slide_watchbtn {
        padding: 0.7rem 1.5rem !important;
    }

    .banner-content {
        margin-top: 1rem;
    }

    #groupCarouselsSection {
        margin-top: 0;
    }

    #groupCarouselsSection .viewall-link {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 300px) {
    html, body {
        font-size: 10px;
    }
}