$primary: #07294C;
$secondary: #037A9A;
$info: #00C8D5;
$light: #BBD8E7;
$dark: #021127;
$success: #022D48;
$danger : #FFF95B;
$yellow: #FFCD00;
$warning: #011A33;
$lighter: #67AFC2;
$page: #9AC9D6;
$grey2: #DDE9F4;  
$bluedark: #010A26;
$font-size-base: 1rem;
$font-size-sm: 0.8rem;
$font-size-lg: $font-size-base * 2;
$h1-font-size: $font-size-base * 1.875;
$h3-font-size: $font-size-base * 1.125;
$h2-font-size: $font-size-base * 1.25;
$h4-font-size: $font-size-base * 1.75;
$h5-font-size: $font-size-base * 0.875;
$h6-font-size: $font-size-base * 1;
$h7-font-size: $font-size-base * 2;
$h8-font-size: $font-size-base * 1.5;


$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size,
  8: $h8-font-size
);


$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "lighter":    $lighter,
  "page":       $page,
  "bluedark":   $bluedark,
  "grey2":      $grey2,
  "yellow":     $yellow,
) !default;

$font-family-base: "Inter", sans-serif;

@import "../node_modules/bootstrap/scss/bootstrap.scss";