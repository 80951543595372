.input-wrapper input{
    width: 90%;
}

.input-wrapper ::placeholder{
    line-height: 1.21rem;
    color: #BBD4EA;
}

.input-wrapper input:focus {
    outline: none;
  }

.input-wrapper input:focus::placeholder {
color: transparent;
}

.admin-input-wrapper input{
  width: 90%;
}

.admin-input-wrapper ::placeholder{
  line-height: 1.21rem;
  color: #037A9A80;
}

.quality-styles{
    margin: 0;
    color: #00C8D5;
    background: #037A9A;
    border: #BBD4EA !important;
}

.quality-styles:hover{
    background-color: #00C8D5 !important;
    color: #037A9A !important;
}

.admin-input-wrapper input:focus {
  outline: none;
}

.admin-input-wrapper input:focus::placeholder {
  color: transparent;
}

.search-wrapper ::placeholder {
    color: #BBD4EA;
    font-size: 1rem;
  }
  
.admin-search-wrapper ::placeholder {
    color: #037A9A;
    font-size: 1rem;
  }

.search-input:focus {
outline: none !important
}

.addSearchDropdown-item:hover {
    background: #f4f4f4;
  }

.profile-menu-object {
cursor: pointer;
}

.profile-menu-object:hover{
background: #BBD8E7;
}

.bunny-player-container {
  max-width: 100%;
  margin: auto;
  position: relative;
  /* padding-top: 56.25%; */
  aspect-ratio: 16/9;
}

.bunny-player-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#playlist-input::placeholder{
    font-size: 15px;
    font-weight: 400;
    color: #037A9A
  }
  
  #playlist-input:focus{
    pointer-events: none;
    outline: 0.1vw solid #037A9A;
  }
  
  .filter-component {
    width: 18%;
    height: 100%;
  }

  .admin-filter-component {
    width: 15%;
    height: 100%;
  }

  #find-buttons{
    width: 10%;
    height: 75%;
  }

  #admin-find-buttons{
    width: 6%;
    height: 75%;
  }
  
  .addToplaylist-list-wrapper {
    overflow-x:hidden;
    overflow-y: scroll;
    word-break: break-all;
    width: 100%;
    max-height: 10rem;
  }
  
  .addToplaylist-list-wrapper::-webkit-scrollbar {
    width: 0.3rem;
  }
  
  #my-tooltip{
    z-index: 1000;
  }

  .addToplaylist-list-wrapper::-webkit-scrollbar-track {
    border-radius: 0.5;
    box-shadow: inset 0 0 0.2vw rgba(0, 0, 0, 0.25);
  }
  
  .addToplaylist-list-wrapper::-webkit-scrollbar-thumb {
    border-radius: 0.5vw;
    background-color: #037A9A;
  }

  .groups-components{
    font-size: 1.25em;
  }



  #button-prev{
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: 100;
  }

  #button-next{
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    z-index: 100;
  }
  


.slider-track {
  display: flex;
}
  
  .slide-wrapper-for-four:not(:last-child) {
    margin-right: .965vw;
}
  .slide-wrapper-for-six:not(:last-child) {
    margin-right: .5vw;}


    .rnc__notification-item{
      box-shadow: none;
    }

    .input::placeholder{
      color: #037A9A;
    }
    
    .input:focus{
      outline: none;
    } 


    .album-search-input::placeholder{
      color: #BBD8E7;
    }
    
    .album-search-input:focus{
      outline: none;
    } 
  
    .side_slide{
      height: fit-content;
      max-height: 40vw;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    
    .side_slide::-webkit-scrollbar {
      width: 0.3vw;
    }
    
    .side_slide::-webkit-scrollbar-track {
      border-radius: 2vw;
      box-shadow: inset 0 0 0.5vw rgba(0, 0, 0, 0.25);
    }
    
    .side_slide::-webkit-scrollbar-thumb {
      border-radius: 2vw;
      background-color: #037A9A;
    }

    .ring_container{
        width: 6.2rem;
        height: 6.2rem;
        border: 0.12rem solid #00C8D5;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ring{
      background-color: #021127;
      width: 5.6rem;
      height: 5.6rem;
      border: 0.3rem solid #021127;
      border-radius: 50%;
      border-top: 0.5rem solid #00C8D5;  
      box-shadow: 0 0 0 0.1rem #021127;
    }

    #analyze-text{
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 4.5em; /* Approx. 3 lines */
        line-height: 1.3em;
    }

    #analyze-button{
        height:5rem;
        width:5rem;
        font-size:0.8rem
      }
  
  
   .animate{
      animation: animate 4s linear infinite;
    }

    .addVideo{
      position: absolute;
      top: 0;
      right: 0;
      /* width: 100%; */
      height: 100%;
      background-color: rgba(255, 0, 0, 0.5); /* Semi-transparent red */
      z-index: 1; /* Higher than parent z-index */
    }

    .addVideo::before{
      content: "";
      position: fixed;
      top: 0;
      right: 0;
      /* width: 100vw; */
      height: 100vh;
      z-index: -1;
    }
  

    .visible {
      opacity: 1;
      /*transition: all 1s linear;*/
    }
  
    .hidden {
        opacity: 0;
        transition: all 1s linear;
        pointer-events: none;
    }

    @keyframes animate {
      0%
      {
          transform: rotate(0deg);
      }
      100%
      {
          transform: rotate(360deg);
      }
    }
  
  .side-playlist-info{
    max-height: 100%;
  }


  .results-wrapper {
    overflow-x:hidden;
    overflow-y: scroll;
    word-break: break-all;
    width: 100%;
    max-height: 85vh;
  }
  
  .results-wrapper::-webkit-scrollbar {
    width: 0.3rem;
  }
  
  .results-wrapper::-webkit-scrollbar-track {
    border-radius: 0.5;
    box-shadow: inset 0 0 0.2vw rgba(0, 0, 0, 0.25);
  }
  
  .results-wrapper::-webkit-scrollbar-thumb {
    border-radius: 0.5vw;
    background-color: #037A9A;
  }


  #banner{ 
    min-height:75vh;
    height:75vh;
  }

    .bottom_text{
        font-size: 38px;
    }

    .bottom_buttons{
        font-size: 24px;
    }


  @media only screen and (max-width: 1388px) {
    .filter-component {
      width: 50%;
      height: auto;
    }

    #find-buttons{
      width: 20%;
      height: auto;
    }

    .bottom_text{
        font-size: 38px;
    }

    .genre_titles{
        font-size: 20px;
        font-weight: 500;
    }
  }

@media only screen and (max-width: 990px) {
    .side_slide{
      min-height: 8.8rem;
      height:auto;
      max-height: 50vw;
    }
  
    .filter-component {
      width: 100%;
      height: auto;
    }
  #find-buttons{
    width: 30%;
    height: auto;
  }

  .bottom_text{
    font-size: 18px;
}

    .bottom_buttons{
        font-size: 16px;
    }


    #banner{ 
        min-height:50vh;
        height:50vh
      }

  }
  
  @media only screen and (max-width: 600px) {
    .side_slide{
      min-height: 4.5rem;
      height:auto;
      max-height: 60vw;
    }

    /* .bottom_text{
        font-size: 18px;
    } */
  
  }

