.progress-slider-container {
    --progress-bar-height: 4px
}

.progress-slider-container {
    position: relative;
    width: 100%;
}

.progress-range {
    -webkit-appearance: none;
    background-color: white;
    height: var(--progress-bar-height);
    width: 100%;
    cursor: pointer;
    opacity: 0;
    margin: 0 auto;
}

.progress-slider-container ::before {
    content: '';
    background-color: white;
    opacity: 0.5;
    width: 99%;
    height: var(--progress-bar-height);
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    /* opacity: 1; */
}

.thumb {
    width: var(--thumb-width);
    height: var(--thumb-height);
    /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0,753); */
    z-index: 3;
    background: white;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    transform: translate(0%, -50%);
    pointer-events: none;
    user-select: none;
    visibility: hidden;
}


input[type="range"]::-webkit-slider-thumb {
    /* -webkit-appearance: none; */
    visibility: hidden;
  }
  
  input[type="range"]::-moz-range-thumb {
    visibility: hidden;
  }
  
  input[type="range"]::-ms-thumb {
    visibility: hidden;
  }

.progress-bar-cover {
    background-color: white;
    width: 50%;
    height: var(--progress-bar-height);
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    user-select: none;
    pointer-events: none;
}

.progress-range {
    -webkit-appearance: 'none';
    background-color: transparent;
    height: 10px;
    width: 100%;
    cursor: pointer;
    opacity: 1;
    margin: 0 auto;
}