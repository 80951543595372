* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html { 
  font-size: 1rem;
  min-height: 100vh;
  height: 100vh
}

body {
  margin: 0;
  font-family: "Inter";
  overflow-y: scroll;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI','Inter', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  height: 100vh;
}


::-webkit-scrollbar {
  width: 0.5rem;
  background: #022D48;
}

::-webkit-scrollbar-track {
  border-radius: 0.5;
  box-shadow: inset 0 0 0.2vw rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-thumb {
  border-radius: 0.5vw;
  background-color: #67AFC2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
